import * as React from "react";
import { Col, Row } from "reactstrap";
import EsopVideo24 from "./videos/EsopVideo24";

function EsopFaq() {
  return (
    <>
      <Row>
        <div className="esop-banner">
          <div className="clipped">
            <h3>ESOP FAQ</h3>
          </div>
        </div>
      </Row>

      <Col xs={12}>
      <div><EsopVideo24/></div>
        <div>
          <div className="page-heading"></div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/MoneyBag.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">What is ESOP?</h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              An Employee Stock Ownership Plan (ESOP) is an employee retirement
              benefit plan that is 100% funded by Scheels.
            </p>
            <br />
          </div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/PrideOfOwnership.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">
              How did Scheels become an ESOP company?
            </h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              In 1991, Chuck Scheel and Fred Scheel sold their company stock to
              the ESOP to begin <br />
              our retirement plan. This plan was created to provide greater
              incentive and motivation for our associates. <br />
            </p>
            <br />
          </div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/Callendar.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">When am I eligible to participate?</h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              An associate is eligible to participate in the Plan after
              completing one Year of Service (defined below) and attaining age
              21.
            </p>
            <br />
          </div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/PushMoney.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">
              How do I become a vested participant in the ESOP?
            </h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              With 3 Years of Service (defined below) and attaining the age of
              21, you can become a vested ESOP participant. A vested participant
              means that you own your balance once you leave employment at
              Scheels. If you leave Scheels prior to attaining 3 Years of
              Service, your balance will be forfeited.
            </p>
            <br />
          </div>

          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/Callendar.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">
              What is considered a Year of Service?
            </h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              To earn a Year of Service, you must be credited with at least 1000
              Hours of Service during any Plan Year. You will have completed a
              Year of Service if, at the end of your first 12 consecutive months
              of employment with us, you have been credited with at least 1000
              Hours of Service. If you have not been credited with at least 1000
              Hours of Service by the end of your first 12 consecutive months of
              employment, you will have completed a Year of Service at the end
              of any following Plan Year (calendar year) during which you were
              credited with at least 1000 Hours of Service.
            </p>
            <br />
          </div>
          <div style={{ width: "100%" }}>
            <h2 className="faq-question">ESOP Eligibility Examples</h2>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/ESOP01.png")}
              alt="Esop Timeline"
            ></img>
            <hr />
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/ESOP02.png")}
              alt="Esop Timeline"
            ></img>
          </div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/QuestionMark.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">Who contributes to the ESOP?</h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              Unlike a 401(k) plan, participants do not contribute their own
              money to the ESOP. Scheels makes an annual contribution to the
              ESOP. The annual contribution is estimated to be approximately 4%
              of your gross income.
            </p>
            <br />
          </div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/MoneyBag.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">
              How do I continue to receive an annual contribution?
            </h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              An associate must work 1000 hours (based on paid dates) and be
              actively employed on 12/31 in order to be eligible for the
              contribution for the year.
              <br />
              Once you reach age 40, have 10 or more Years of Service and do not
              earn a Year of Service in the preceding Plan Year, your stock will
              be converted to cash within the Plan. This means that you will no
              longer own company stock but you will have a directed cash
              investment account through Bell Bank.
            </p>
            <br />
          </div>

          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/PrideOfOwnership.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">
              What happens to my ESOP if I leave employment?
            </h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              If you are 100% vested in your ESOP balance, upon leaving
              employment at Scheels you are eligible to either rollover the
              funds into a Qualified IRA/Employer Qualified Retirement Account
              or take a direct payment, in cash, of the funds.
            </p>
            <br />
          </div>

          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/MoneyCircle.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">
              When will I receive my initial ESOP communication from the
              Corporate Office after leaving employment with Scheels?
            </h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              The Corporate Office will contact you by letter within a few weeks
              after you have been inactivated in our payroll system. If you
              leave between January and June, you will be sent a letter
              including your new ESOP statement and Distribution Form, generally
              in July/August. If you leave from July through December 31st, you
              will be sent a letter with the Distribution Form within a few
              weeks after inactivation in our payroll system.
            </p>
            <br />
          </div>

          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/Callendar.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">
              When will I receive my ESOP payment after leaving employment with
              Scheels?
            </h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              Once you complete and return your Distribution Form back to the
              Corporate Office, we will normally issue payment within two weeks
              of receipt. Remember, if you leave Scheels between January and
              June, this will happen typically in July/August.
            </p>
            <br />
          </div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/MoneyCircle.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">
              Does Scheels withhold taxes on direct payments?
            </h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              Because these are stock distributions, no Federal or State tax
              will be withheld, but you will be liable for Federal and any
              applicable State and Local taxes on the taxable portion of your
              distribution. This information will be provided to you in the form
              of a 1099-R after the end of the year for your tax reporting
              purposes.
            </p>
            <br />
          </div>

          <div
            style={{
              width: "100%",
              backgroundColor: "red",
              paddingTop: "2em",
              paddingBottom: "2em",
              color: "white",
            }}
          >
            <h4 className="faq-question">
              If you have additional questions about Scheels ESOP, please reach
              out to your store HR or CO Office Lead.
            </h4>
          </div>
          <br />
          <br />
        </div>
      </Col>
    </>
  );
}
export default EsopFaq;
