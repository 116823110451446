import { ActionObject } from "../../models/redux/_ActionObject";
import { IS_LOADING } from "./../constants/loadingStatuses.constants";

const loadingReducer = (state = false, action: ActionObject<boolean>) => {
	switch (action.type) {
		case IS_LOADING:
			return action.payload;
		default:
			return false;
	}
};

export default loadingReducer;
