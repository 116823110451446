import { all } from 'redux-saga/effects';
import userSaga from './user.saga';
import certificateSaga from './certificate.saga';
import shareholderSaga from './shareholder.saga';
import subaccountSaga from './subaccount.saga';
import valuationSaga from './valuation.saga';
import esopValuationSaga from './esopValuation.saga';
import esopSaga from './esop.saga';

// rootSaga is the primary saga.
// It bundles up all of the other sagas so our project can use them.
// This is imported in index.js as rootSaga

// some sagas trigger other sagas, as an example
// the registration triggers a login
// and login triggers setting the user

export default function* rootSaga() {
    yield all([
        certificateSaga(),
        shareholderSaga(),
        subaccountSaga(),
        valuationSaga(),
        userSaga(),
        esopValuationSaga(),
        esopSaga()
    ]);
}