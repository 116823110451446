import * as React from "react";
import { useSelector } from "react-redux";
import ReduxState from "../models/redux/_ReduxState";
import User from "../models/User";

function Header() {
	const user: User = useSelector((store: ReduxState) => store.userReducer);

	return (
		<>
			<div className="header">
				<h2 className="header-text">
					{user.firstName ? "Welcome, " + user.firstName + "!" : ""}
				</h2>
				{/* <Row>
					<Col>
						<img
							style={{ width: "100%", height: "100%", borderRadius: "0" }}
							src={require("./pictures/SEOHeader4.png")}
							alt="Scheels company Header"></img>
					</Col>
				</Row> */}
			</div>
		</>
	);
}

export default Header;
