import { Alert, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { VALIDATE_USER } from "../redux/constants/user.constants";
import { AuthenticationStatus } from "../redux/constants/loadingStatuses.constants";
import ReduxState from "../models/redux/_ReduxState";
import User from "../models/User";
function Login() {
    const dispatch = useDispatch();
    const loginStatus: AuthenticationStatus = useSelector((store: ReduxState) => store.loginReducer);
    const [googleResponse, setGoogleResponse] = useState("");
    useEffect(() => {
        if (googleResponse) {
            const client = google.accounts.oauth2.initTokenClient({
                hint: jwt_decode<User>(googleResponse).email,
                client_id: process.env.REACT_APP_GOOGLE_CLIENTID!,
                scope: "profile email https://www.googleapis.com/auth/admin.directory.user.readonly",
                callback: (tokenResponse: google.accounts.oauth2.TokenResponse) => {
                    if (tokenResponse && tokenResponse.access_token) {
                        dispatch({ type: VALIDATE_USER, payload: { googleResponse, token: tokenResponse.access_token } });
                    }
                },
            });
            client.requestAccessToken();
        }
    }, [googleResponse, dispatch]);
    const responseSuccessGoogle = async (googleResponse: any) => setGoogleResponse(googleResponse.credential);
    return (
        <div className="row d-flex justify-content-center">
            <div className="col-1">
                <GoogleLogin useOneTap onSuccess={(e) => responseSuccessGoogle(e)} size="large" shape="circle" text={"signin"} />
            </div>
            {loginStatus === AuthenticationStatus.Failed ? (
                <Row>
                    <Col>
                        <Alert color="danger">Unable to log in with these credentials</Alert>
                    </Col>
                </Row>
            ) : (
                <></>
            )}
        </div>
    );
}

export default Login;
