import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { FETCH_ESOP, SET_ESOP } from "../constants/esop.constants";
import { IS_LOADING } from "../constants/loadingStatuses.constants";
import { ResponseStatus } from "../../models/ResponseStatus";
import EsopData from "../../models/esopData";

import * as Sentry from "@sentry/browser";

function* fetchEsop(action: any) {
    try {
        let emplid = action.payload.user.employeeId;
        console.log(action.payload.user.employeeId);
        console.log("fetchEsop saga called");

        yield put({ type: IS_LOADING, payload: true });

        const response: ResponseStatus<EsopData> = yield axios.get(`${process.env.REACT_APP_SEO_API ?? "api"}/ESOP/GetEsopSummaryByEmployeeId/${emplid}`, {
            headers: {
                Authorization: `bearer ${action.payload.user.tokenId}`,
                GCPCheck: `${action.payload.user.accessToken}`,
            },
        });
        //console.log("Saga - " + response.data);
        yield put({
            type: SET_ESOP,
            payload: response.data,
        });
    } catch (error) {
        console.log(` There was an error on ESOP! -> ${error}`);
        Sentry.captureException(error);
    } finally {
        yield put({ type: IS_LOADING, payload: false });
    }
}
function* esopSaga() {
    yield takeEvery(FETCH_ESOP, fetchEsop);
}
export default esopSaga;
