import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { IS_LOADING } from "../constants/loadingStatuses.constants";
import { FETCH_SHAREHOLDERS, SET_SHAREHOLDERS } from "./../constants/shareholder.constants";
import Shareholder from "./../../models/Shareholder";
import { ResponseStatus } from "../../models/ResponseStatus";
import * as Sentry from "@sentry/browser";
function* fetchShareholder(action: any) {
    try {
        let emplid = action.payload.user.employeeId;
        //console.log(action.payload.user.employeeId);
        //console.log("fetchShareholder saga called")
        yield put({ type: IS_LOADING, payload: true });
        //console.log(`${process.env.REACT_APP_SEO_API ?? "api"}/Shareholder/GetByEmployeeId/2576`);
        const response: ResponseStatus<Shareholder> = yield axios.get(`${process.env.REACT_APP_SEO_API ?? "api"}/Shareholder/GetByEmployeeId/${emplid}`, {
            headers: {
                Authorization: `bearer ${action.payload.user.tokenId}`,
                GCPCheck: `${action.payload.user.accessToken}`,
            },
        });
        //console.log("Saga - " + response.data.shareholderStatus);
        yield put({
            type: SET_SHAREHOLDERS,
            payload: response.data,
        });
        //console.log(`Pending orders:`);
    } catch (error) {
        console.log(`There was an error! -> ${error}`);
        Sentry.captureException(error);
    } finally {
        yield put({ type: IS_LOADING, payload: false });
    }
}

function* ShareholderSaga() {
    yield takeEvery(FETCH_SHAREHOLDERS, fetchShareholder);
}

export default ShareholderSaga;
