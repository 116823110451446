import React, { useEffect } from 'react';

const EsopVideo24: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://player.vimeo.com/api/player.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ textAlign: 'center', fontFamily: "'Lato', sans-serif" }}>
      <h1 style={{
        fontSize: '2.5rem',
        fontWeight: 'bold',
        marginBottom: '20px',
        color: '#cc0000'
      }}>
        {/*optional header*/}
      </h1>
      <div style={{ padding: '26.25% 0 0 0', position: 'relative' }}>
        <iframe
          src="https://player.vimeo.com/video/1016281805?h=655a3bb4e4&badge=0&autopause=0&player_id=0&app_id=58479"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '80%' }}
          title="2024_ESOP"
        ></iframe>
      </div>
      <hr style={{
        border: '0',
        height: '1px',
        backgroundColor: 'grey',
        marginTop: '0px',
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto'
      }} />
    </div>
  );
};

export default EsopVideo24;
