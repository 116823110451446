import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Nav, NavItem, NavLink, Row, Col } from "reactstrap";
import User from "../models/User";
import { SET_USER } from "../redux/constants/user.constants";
import "./NavMenu.css";
import { GoogleLogout } from "react-google-login";
import moment from "moment";
import ReduxState from "../models/redux/_ReduxState";

function NavMenu() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user: User = useSelector((store: ReduxState) => store.userReducer);

    const signOut = () => {
        dispatch({ type: SET_USER, payload: {} });
        navigate("/");
    };

    //validate the session
    React.useEffect(() => {
        if (user.loginTime !== undefined && moment().format("DD") !== moment(user.loginTime).format("DD")) {
            dispatch({ type: SET_USER, payload: {} });
            navigate("/");
        }
    }, [dispatch, navigate, user]);

    return (
        <div id="nav-bar">
            <Nav pills vertical>
                <NavItem>
                    <NavLink>
                        <Col sm="12">
                            <br />
                            <img style={{ borderRadius: "0%", width: "90%" }} src={require("./pictures/Logo.png")} alt="Scheels Logo"></img>
                            <br />
                        </Col>
                    </NavLink>
                    {user.accessToken !== undefined && user.accessToken !== "" ? (
                        <>
                            <NavLink>
                                <Button color="danger-outline" block className=" nav-btn" onClick={() => navigate("/home")}>
                                    <i className="fas fa-home icon" />
                                    Home
                                </Button>
                            </NavLink>
                            <NavLink>
                                <Button color="danger-outline" block className=" nav-btn" onClick={() => navigate("/stock")}>
                                    <i className="fas fa-chart-line icon" />
                                    Common Stock
                                </Button>
                            </NavLink>
                            <NavLink>
                                <Button color="danger-outline" block className=" nav-btn" onClick={() => navigate("/stockredemption")}>
                                    <i className="fas fa-piggy-bank icon" />
                                    Common Stock Redemption
                                </Button>
                            </NavLink>
                            <NavLink>
                                <Button color="danger-outline" block className=" nav-btn" onClick={() => navigate("/faq")}>
                                    <i className="fas fa-question icon" />
                                    Common Stock FAQ
                                </Button>
                            </NavLink>
                            <NavLink>
                                <Button color="danger-outline" block className=" nav-btn" onClick={() => navigate("/stockhistory")}>
                                    <i className="fas fa-table icon" />
                                    Common Stock History
                                </Button>
                            </NavLink>
                            <NavLink>
                                <a className=" nav-btn btn btn-danger-outline btn-block" href="../ShareholdersAgreement.pdf" target="blank">
                                    <i className="fas fa-solid fa-file-pdf icon"></i>
                                    Shareholders Agreement
                                </a>
                            </NavLink>
                            <NavLink>
                                <Button color="danger-outline" block className=" nav-btn" onClick={() => navigate("/esop")}>
                                    <i className="fas fa-piggy-bank icon" />
                                    ESOP
                                </Button>
                            </NavLink>
                            <NavLink>
                                <Button color="danger-outline" block className=" nav-btn" onClick={() => navigate("/esopcalc")}>
                                    <i className="fas fa-calculator icon" />
                                    ESOP Projection Tool
                                </Button>
                            </NavLink>
                            <NavLink>
                                <Button color="danger-outline" block className=" nav-btn" onClick={() => navigate("/esopfaq")}>
                                    <i className="fas fa-question icon" />
                                    ESOP FAQ
                                </Button>
                            </NavLink>
                            <NavLink>
                                <Button color="danger-outline" block className=" nav-btn" onClick={() => navigate("/esophistory")}>
                                    <i className="fas fa-table icon" />
                                    ESOP History
                                </Button>
                            </NavLink>
                            <NavLink>
                                <a className=" nav-btn btn btn-danger-outline btn-block" href="../ESOPSummaryPlanDescription.pdf" target="blank">
                                    <i className="fas fa-solid fa-file-pdf icon"></i>
                                    ESOP Summary Plan
                                </a>
                            </NavLink>

                            <hr />
                            <NavLink>
                                <Row>
                                    {user.pictureUrl && (
                                        <>
                                            <Col sm="5">
                                                <img src={user.pictureUrl} height={45} alt="Profile" />
                                            </Col>
                                            <Col sm="7">
                                                <GoogleLogout
                                                    clientId="3653309562-qje2ujln7p89mrgg11n6h4uhq0oteuo5.apps.googleusercontent.com"
                                                    buttonText="Logout"
                                                    onLogoutSuccess={signOut}
                                                />
                                            </Col>
                                        </>
                                    )}
                                    {!user.pictureUrl && (
                                        <Col sm="12">
                                            <GoogleLogout
                                                clientId="3653309562-qje2ujln7p89mrgg11n6h4uhq0oteuo5.apps.googleusercontent.com"
                                                buttonText="Logout"
                                                onLogoutSuccess={signOut}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </NavLink>
                        </>
                    ) : (
                        <></>
                    )}
                </NavItem>
            </Nav>
        </div>
    );
}

export default NavMenu;
