import { useSelector } from "react-redux";
import ReduxState from "../models/redux/_ReduxState";
import Login from "./Login";
import React, { ComponentType } from "react";

export function Authentication({ Component }: { Component: ComponentType }) {
    const user = useSelector((store: ReduxState) => store.userReducer);
    //if user has id, they are logged in. if not, display the login page instead
    return <>{user.employeeId ? <Component /> : <Login />}</>;
}
