import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import Home from "./components/Home";
import Stock from "./components/Stock";
import Faq from "./components/Faq";
import "@progress/kendo-theme-default/dist/all.css";
import "./custom.css";
import { Col, Row } from "reactstrap";
import Header from "./components/Header";
import NavMenu from "./components/NavMenu";
import EsopFaq from "./components/EsopFaq";
import EsopCalc from "./components/EsopCalc";
import "./App.css";
import Esop from "./components/Esop";
import EsopHistory from "./components/EsopHistory";
import StockHistory from "./components/StockHistory";
import { Authentication } from "./components/Authentication";
import StockRedemption from "./components/StockRedemption";
function App() {
    const navigate = useNavigate();
    function ErrorFallback({ error }: FallbackProps) {
        return (
            <div className="page-main">
                <Row>
                    <Col xs={12}>
                        <h1 style={{ textAlign: "center" }}>Something went badly, badly wrong! Please Contact SIS</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <pre>{error?.message}</pre>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <pre>{error?.stack}</pre>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} style={{ textAlign: "center" }}></Col>
                </Row>
            </div>
        );
    }
    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                navigate("/");
            }}
        >
            <main className="App">
                <div className="page-main">
                    <Row>
                        <Col xs={2} style={{ margin: "0", padding: "0" }}>
                            <NavMenu />
                        </Col>
                        <Col xs={10} style={{ paddingRight: "0px" }}>
                            <Row className="header" style={{ width: "100%" }}>
                                <Header />
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Routes>
                                        <Route path="/" element={<Authentication Component={Home} />} />
                                        <Route path="/home" element={<Authentication Component={Home} />} />
                                        <Route path="/stock" element={<Authentication Component={Stock} />} />
                                        <Route path="/esop" element={<Authentication Component={Esop} />} />
                                        <Route path="/esopcalc" element={<Authentication Component={EsopCalc} />} />
                                        <Route path="/faq" element={<Authentication Component={Faq} />} />
                                        <Route path="/esopfaq" element={<Authentication Component={EsopFaq} />} />
                                        <Route path="/esophistory" element={<Authentication Component={EsopHistory} />} />
                                        <Route path="/stockhistory" element={<Authentication Component={StockHistory} />} />
                                        <Route path="/stockredemption" element={<Authentication Component={StockRedemption} />} />
                                    </Routes>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </main>
        </ErrorBoundary>
    );
}

export default App;
