import jwt_decode from "jwt-decode";
import moment, { Moment } from "moment";

export default class User {
    constructor(jwt?: string, accessToken?: string) {
        if (jwt && accessToken) {
            this.jwt = jwt;
            let decoded: {
                email: string;
                name: string;
                picture: string;
                given_name: string;
                family_name: string;
            } = jwt_decode(jwt);
            console.log(decoded);
            this.pictureUrl = decoded.picture;
            this.accessToken = accessToken;
            this.fullName = decoded.name;
            this.email = decoded.email;
            this.tokenId = jwt;
        }
    }
    public tokenId!: string;
    public serverAuthToken: string = "";
    public email: string = "";
    public accessToken: string = "";
    public employeeId: string = "";
    public fullName: string = "";
    public pictureUrl: string = "";
    public storeNumber: string = "";
    public storeAbbreviation: string = "";
    public firstName!: string;
    public role: string = "";
    public jwt: string = "";
    public loginTime: Moment = moment();
}
