import { ActionObject } from "../../models/redux/_ActionObject";
import {
	AUTHENTICATION_PROCESSING,
	AUTHENTICATION_FAILED,
	AUTHENTICATION_SUCCEEDED,
	AuthenticationStatus,
} from "./../constants/loadingStatuses.constants";

const loginReducer = (
	state = {},
	action: ActionObject<AuthenticationStatus>
) => {
	switch (action.type) {
		case AUTHENTICATION_PROCESSING:
			return AuthenticationStatus.Processing;
		case AUTHENTICATION_FAILED:
			return AuthenticationStatus.Failed;
		case AUTHENTICATION_SUCCEEDED:
			return AuthenticationStatus.Succeeded;
		default:
			return AuthenticationStatus.AwaitingUser;
	}
};

export default loginReducer;
