export const AUTHENTICATION_PROCESSING = 'AUTHENTICATION_PROCESSING';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const AUTHENTICATION_SUCCEEDED = 'AUTHENTICATION_SUCCEEDED';
export const IS_LOADING = "IS_LOADING";

export enum AuthenticationStatus {
    AwaitingUser,
    Processing, 
    Failed, 
    Succeeded
}
