/* eslint react/style-prop-object: 0 */
// The reason for this disable is ChartSeriesItem wants style="smooth" rather style={{ stuff }} //
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Col, Row, Table } from "reactstrap";
import User from "../models/User";
import { FETCH_SHAREHOLDERS } from "../redux/constants/shareholder.constants";
import { IS_LOADING } from "../redux/constants/loadingStatuses.constants";
import LoadingOverlay from "react-loading-overlay";

import { Chart, ChartSeries, ChartSeriesItem, ChartSeriesItemTooltip, ChartTitle } from "@progress/kendo-react-charts";
import "hammerjs";
import { PDFExport } from "@progress/kendo-react-pdf";
import "./../custom.css";

import ReduxState from "../models/redux/_ReduxState";
import EsopData from "../models/esopData";
import ESOPValuation from "../models/esopValuation";
import { FETCH_ESOP } from "../redux/constants/esop.constants";
import { FETCH_ESOP_VALUATION } from "../redux/constants/esopValuation.constants";
import EsopGrowthChart from "../models/EsopGrowthChart";

function Esop() {
    const currentYear = new Date().getFullYear();
    const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    const navigate = useNavigate();
    const user: User = useSelector((store: ReduxState) => store.userReducer);
    const loading: boolean = useSelector((store: ReduxState) => store.loadingReducer);
    const Esops: EsopData[] = useSelector((store: ReduxState) => store.esopReducer);
    const EsopValuations: ESOPValuation[] = useSelector((store: ReduxState) => store.ESOPValuationReducer);
    const dispatch = useDispatch();
    const [growthChartData, setGrowthChartData] = useState<Array<EsopGrowthChart>>([]);

    // This is the function behind the export to pdf
    const pdfExportComponent: any = React.useRef(null);
    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    };

    // This useEffect handles the calling all data from the backend to the frontend.
    useEffect(() => {
        if (user!.accessToken === undefined || user!.accessToken === "") {
            navigate("/", { replace: true });
        } else {
            dispatch({ type: IS_LOADING, payload: true });
            dispatch({ type: FETCH_SHAREHOLDERS, payload: { user } });

            dispatch({ type: FETCH_ESOP, payload: { user } });
            dispatch({ type: FETCH_ESOP_VALUATION, payload: { user } });
        }
    }, [dispatch, navigate, user]);

    // This useEffect handles the growthChartData (ESOPEvaluation side of things)
    useEffect(() => {
        let cd: EsopGrowthChart[] = [];
        for (let index = 0; index < EsopValuations.length; index++) {
            EsopValuations[index].adjustedValue = EsopValuations[index].price / EsopValuations[index].divisor;

            if (EsopValuations[index].year > 2005) {
                cd.push({
                    year: EsopValuations[index].year,
                    value: EsopValuations[index].adjustedValue.toFixed(2),
                });
            }
        }
        setGrowthChartData(cd);
    }, [EsopValuations, dispatch]);

    // The function behind the chart ... we passing the growth overtime in as the growthChartData
    const HistoricalChart = () => (
        <Chart>
            <ChartTitle text="ESOP Share Value" />
            <ChartSeries>
                <ChartSeriesItem data={growthChartData} field="value" categoryField="year" type="line" style="smooth">
                    <ChartSeriesItemTooltip visible={true} format="{0}" />
                </ChartSeriesItem>
            </ChartSeries>
        </Chart>
    );

    // This table renders if the person has esop records
    const EspoGrowthTable = () => (
        <>
            <Table bordered hover size="sm" striped>
                <thead className="table-header">
                    <tr>
                        <th>Plan Year Ending</th>
                        <th>Contribution</th>
                        <th>Shares</th>
                        <th>Account Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {Esops.map((esop: EsopData) => (
                        <tr key={esop.esopSummaryId}>
                            <td>{esop.year}</td>
                            <td>{currencyFormatter.format(esop.contribution)}</td>
                            <td>{esop.shares}</td>
                            <td>{currencyFormatter.format(esop.balance)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );

    // This table renders if the person doesn't have esop.
    const DefaultTable = () => (
        <>
            <Table bordered hover size="sm" striped>
                <thead className="table-header">
                    <tr>
                        <th>Plan Year Ending</th>
                        <th>Contribution</th>
                        <th>Shares</th>
                        <th>Account Balance</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>0</td>
                        <td>{currencyFormatter.format(0)}</td>
                        <td>0</td>
                        <td>{currencyFormatter.format(0)}</td>
                    </tr>
                </tbody>
            </Table>
        </>
    );

    return (
        <>
            <LoadingOverlay spinner text="Loading money..." active={loading}>
                <Row style={{ height: "0px" }}>
                    <Col
                        xs={11}
                        style={{
                            textAlign: "right",
                            position: "relative",
                            bottom: "-20px",
                        }}
                    >
                        <button onClick={exportPDFWithComponent} className="btn dark-red-button pdfHidden">
                            Export To PDF
                        </button>
                    </Col>
                </Row>
                <PDFExport ref={pdfExportComponent} paperSize="auto" margin={40} fileName={`ESOP ${user.fullName} | ${new Date().getDay()}`} author="Scheels">
                    <Row>
                        <div className="banner-background"></div>
                        {/* <div style={{position: "relative", backgroundSize: "cover", backgroundPosition: "center", width: "80%"}}>
							<div style={{padding: "10px", width: "600px", color: "#ddd", background: "#ddd", backgroundColor: "#7a0000", textAlign: "left", clipPath: 'polygon(0 0, 100% 0%, 95% 100%, 0% 100%)'}}>
								<h3>Employee Stock Ownership Plan</h3>
							</div>

						</div> */}
                    </Row>
                    <Col lg={11} xs={11}>
                        <Row>
                            <Col
                                lg={6}
                                xs={12}
                                style={{
                                    textAlign: "left",
                                    paddingLeft: "2em",
                                }}
                            >
                                <br />

                                {/* Rendering the esop current value */}
                                <h2 className="padding-top-bottom" style={{ fontWeight: "bolder" }}>
                                    Current ESOP Balance:
                                </h2>
                                <h3 className="red-text padding-top-bottom">
                                    {Esops.length > 0
                                        ? currencyFormatter.format(
                                              Esops.sort((a: EsopData, b: EsopData) => (a.year > b.year ? 1 : -1))[Esops.length - 1]?.balance
                                          )
                                        : currencyFormatter.format(0)}
                                </h3>

                                <p>
                                    {" "}
                                    {Esops.find((x) => x.vestedBalance > 0)
                                        ? "You are 100% vested"
                                        : "You are 0% vested. After working 3 years and 1000 hours per year, you will be 100% vested."}
                                </p>

                                {/* Time as of last year */}
                                <p className="padding-top-bottom">Current value as of 12/31/2023</p>
                            </Col>

                            {/* The chart that holds all the esop history graph */}
                            <Col lg={6} xs={12}>
                                <HistoricalChart />
                            </Col>
                        </Row>
                        <br />
                        <h1>ESOP History</h1>

                        <br />
                        {EsopValuations.length > 0 ? EspoGrowthTable() : DefaultTable()}
                        <p>
                            <strong>Content: </strong>
                            The stock value on this page is updated one time per year and the current value per share as of 12/31/2023 is $3130.36
                            <br />
                            ESOP statements for plan year 2023 and later can be viewed at
                            <a href="https://www.esopconnection.com/scheels/"> https://www.esopconnection.com/scheels/</a>.
                        </p>
                    </Col>
                </PDFExport>
            </LoadingOverlay>
        </>
    );
}

export default Esop;
