import { Certificate } from "crypto";
import { ActionObject } from "../../models/redux/_ActionObject";
import { SET_CERTS } from "./../constants/certificate.constants";

const defaultState: Certificate[] = [];

function certificateReducer(
	state = defaultState,
	action: ActionObject<Certificate[]>
) {
	switch (action.type) {
		case SET_CERTS:
			const newState = action.payload;
			return newState;
		default:
			return state;
	}
}

export default certificateReducer;
