import ESOPValuation from "../../models/esopValuation";
import { ActionObject } from "../../models/redux/_ActionObject";
import { SET_ESOP_VALUATION } from "../constants/esopValuation.constants";

const defaultState: ESOPValuation[] =[];

function ESOPValuationReducer (
    state =defaultState,
    action: ActionObject<ESOPValuation[]>
){
    switch (action.type) {
		case SET_ESOP_VALUATION:
			const newState = action.payload;
			for (let index = 0; index < newState.length; index++) {
				newState[index].adjustedValue =
					newState[index].price / newState[index].divisor;
			}
			return newState;
		default:
			return state;
	}
}

export default ESOPValuationReducer;