/* eslint react/style-prop-object: 0 */
// The reason for this disable is ChartSeriesItem wants style="smooth" rather style={{ stuff }}
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Col, Row, Table } from "reactstrap";
import Certificate from "../models/Certificate";
import Shareholder from "../models/Shareholder";
import Subaccount from "../models/Subaccount";
import User from "../models/User";
import Valuation from "../models/Valuation";
import { FETCH_CERTS } from "../redux/constants/certificate.constants";
import { FETCH_SHAREHOLDERS } from "../redux/constants/shareholder.constants";
import { FETCH_SUBACCOUNT } from "../redux/constants/subaccount.constants";
import { FETCH_VALUATION } from "../redux/constants/valuation.constants";
import { IS_LOADING } from "../redux/constants/loadingStatuses.constants";
import LoadingOverlay from "react-loading-overlay";
import ChartData from "../models/ChartData";
import { Chart, ChartLegend, ChartSeries, ChartSeriesItem, ChartSeriesItemTooltip, ChartTitle } from "@progress/kendo-react-charts";
import "hammerjs";
import { PDFExport } from "@progress/kendo-react-pdf";
import "./../custom.css";
import StockGrowthChart from "../models/StockGrowthChart";
import ReduxState from "../models/redux/_ReduxState";
import moment from "moment";
import { Format } from "@scheels-softdev/frontend-utility-functions";

function Stock() {
    let donutChartData: ChartData[] = [];
    const navigate = useNavigate();
    const user: User = useSelector((store: ReduxState) => store.userReducer);
    const loading: boolean = useSelector((store: ReduxState) => store.loadingReducer);
    const shareholder: Shareholder = useSelector((store: ReduxState) => store.shareholderReducer);
    const certificates: Certificate[] = useSelector((store: ReduxState) => store.certificateReducer);
    const valuations: Valuation[] = useSelector((store: ReduxState) => store.valuationReducer);
    const currentYear = [...valuations].sort((x, y) => y.year - x.year)[0]?.year || moment().add(-1, "year").year();

    const subaccounts: Subaccount[] = useSelector((store: ReduxState) => store.subaccountReducer);
    const dispatch = useDispatch();
    const [uncertShares, setUncertShares] = useState(0);
    const [certShares, setCertShares] = useState(0);
    const [certificatedGrowth, setCertificatedGrowth] = useState(0);
    const [uncertificatedGrowth, setUncertificatedGrowth] = useState(0);
    const [growthChartData, setGrowthChartData] = useState<Array<StockGrowthChart>>([]);
    const pdfExportComponent: any = React.useRef(null);
    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    };

    useEffect(() => {
        if (user!.accessToken === undefined || user!.accessToken === "") {
            navigate("/", { replace: true });
        } else {
            dispatch({ type: IS_LOADING, payload: true });
            dispatch({ type: FETCH_SHAREHOLDERS, payload: { user } });
            dispatch({ type: FETCH_VALUATION, payload: { user } });
        }
    }, [dispatch, navigate, user]);

    useEffect(() => {
        if (shareholder.shareholderId !== undefined) {
            dispatch({ type: FETCH_CERTS, payload: { shareholder, user } });
            dispatch({
                type: FETCH_SUBACCOUNT,
                payload: { shareholder, user },
            });
        }
    }, [shareholder, dispatch, user]);

    useEffect(() => {
        let totalCerts: number = 0;
        let valueAtPurchase: number = 0;
        let valueCurrent: number = 0;
        let growth: number = 0;
        let totalGrowth: number = 0;
        for (let index = 0; index < certificates.length; index++) {
            totalCerts += certificates[index].shares;
        }
        setCertShares(totalCerts);

        for (let index = 0; index < certificates.length; index++) {
            valueAtPurchase = 0;
            valueCurrent = 0;
            growth = 0;
            valueAtPurchase = certificates[index].shares * valuations.find((x) => x.year === certificates[index].year)!.adjustedValue;
            valueCurrent = certificates[index].shares * valuations.find((x) => x.year === currentYear)!.adjustedValue;
            growth = valueCurrent - valueAtPurchase;
            certificates[index].growth = growth;
            totalGrowth += growth;
        }
        setCertificatedGrowth(totalGrowth);
    }, [certificates, valuations, currentYear]);

    useEffect(() => {
        let total: number = 0;
        let valueAtPurchase: number = 0;
        let valueCurrent: number = 0;
        let growth: number = 0;
        let totalGrowth: number = 0;
        for (let index = 0; index < subaccounts.length; index++) {
            total += subaccounts[index].shares;
        }
        setUncertShares(total);

        for (let index = 0; index < subaccounts.length; index++) {
            valueAtPurchase = 0;
            valueCurrent = 0;
            growth = 0;
            valueAtPurchase = subaccounts[index].shares * valuations.find((x) => x.year === subaccounts[index].year)!.adjustedValue;
            valueCurrent = subaccounts[index].shares * valuations.find((x) => x.year === currentYear)!.adjustedValue;
            growth = valueCurrent - valueAtPurchase;
            subaccounts[index].growth = growth;
            totalGrowth += growth;
        }
        setUncertificatedGrowth(totalGrowth);
    }, [subaccounts, valuations, currentYear]);

    useEffect(() => {
        let cd: StockGrowthChart[] = [];
        for (let index = 0; index < valuations.length; index++) {
            valuations[index].adjustedValue = valuations[index].price / valuations[index].divisor;

            if (valuations[index].year > 2005) {
                cd.push({
                    year: valuations[index].year,
                    value: valuations[index].adjustedValue.toFixed(2),
                });
            }
        }
        setGrowthChartData(cd);
    }, [valuations, dispatch]);

    useEffect(() => {
        if (subaccounts.length > 0) {
            dispatch({ type: IS_LOADING, payload: false });
            console.log(subaccounts);
            console.log("loaded");
        }
    }, [subaccounts, certificates, dispatch]);

    const CalculateCurrentTotal = (certs: Certificate[], subs: Subaccount[]) => {
        let shares: number = 0;
        if (certs !== undefined) {
            for (let index = 0; index < certs.length; index++) {
                shares += certs[index].shares;
            }
        }
        if (subs !== undefined) {
            for (let index = 0; index < subs.length; index++) {
                shares += subs[index].shares;
            }
        }
        if (valuations.length !== 0) return shares * valuations.find((x) => x.year === currentYear)!.price;
        else return 0;
    };

    const CalculateUncertificatedGrowth = (sub: Subaccount) => {
        const valueAtPurchase = sub.shares * valuations.find((x) => x.year === sub.year)!.adjustedValue;
        const valueCurrent = sub.shares * valuations.find((x) => x.year === currentYear)!.adjustedValue;
        const growth = valueCurrent - valueAtPurchase;
        return Format.Currency(growth);
    };

    const CalculateCertificatedGrowth = (cert: Certificate) => {
        const valueAtPurchase = cert.shares * valuations.find((x) => x.year === cert.year)!.adjustedValue;
        const valueCurrent = cert.shares * valuations.find((x) => x.year === currentYear)!.adjustedValue;
        const growth = valueCurrent - valueAtPurchase;
        return Format.Currency(growth);
    };

    const CalculateTotalGrowth = () => {
        let valueAtPurchase: number = 0;
        let totalGain: number = 0;

        valueAtPurchase += uncertificatedGrowth + certificatedGrowth;

        totalGain = CalculateCurrentTotal(certificates, subaccounts) - valueAtPurchase;
        donutChartData = [
            { Kind: "Starting Value", Value: totalGain, Color: "#cc0000" },
            { Kind: "Growth", Value: valueAtPurchase, Color: "#7a0000" },
        ];
        return totalGain;
    };

    const donutCenter = () => (
        <span>
            <h3>{((donutChartData[1].Value / donutChartData[0].Value) * 100).toFixed(1)}%</h3>
        </span>
    );
    type labelContentEvent = {
        category: string;
    };
    const labelContent = (e: labelContentEvent) => {
        return `${e.category}`;
    };

    const HistoricalChart = () => (
        <Chart>
            <ChartTitle text="Stock Value" />
            <ChartSeries>
                <ChartSeriesItem data={growthChartData} field="value" categoryField="year" type="line" style="smooth">
                    <ChartSeriesItemTooltip visible={true} format="{0}" />
                </ChartSeriesItem>
            </ChartSeries>
        </Chart>
    );

    const ChartContainer = () => (
        <Chart donutCenterRender={donutCenter}>
            <ChartSeries>
                <ChartSeriesItem
                    type="donut"
                    data={donutChartData}
                    categoryField="Kind"
                    field="Value"
                    colorField="Color"
                    size={30}
                    labels={{
                        visible: true,
                        content: labelContent,
                        position: "outsideEnd",
                        color: "black",
                    }}
                ></ChartSeriesItem>
            </ChartSeries>
            <ChartLegend visible={false} />
        </Chart>
    );

    const CertificatedTable = () => (
        <>
            <h1>Certificated Shares</h1>
            <Table bordered hover size="sm" striped>
                <thead className="table-header">
                    <tr>
                        <th>Year Issued</th>
                        <th>Shares</th>
                        <th>Current Value</th>
                        <th>Growth</th>
                        <th>Stock Certificate #</th>
                    </tr>
                </thead>
                <tbody>
                    {certificates.map((cert: Certificate) => (
                        <tr key={cert.certificateId}>
                            <td>{cert.year}</td>
                            <td>{cert.shares}</td>
                            <td>
                                {Format.Currency(cert.shares * (valuations.find((x) => x.year === currentYear)?.adjustedValue ?? 0))}{" "}
                                {cert.year > currentYear - 5 ? "*" : ""}
                            </td>
                            <td>{CalculateCertificatedGrowth(cert)}</td>
                            <td>{cert.certificateId}</td>
                        </tr>
                    ))}
                    <tr key={"total"} className="totals-Row">
                        <td>Total</td>
                        <td>{certShares}</td>
                        <td>{Format.Currency(certShares * (valuations.find((x) => x.year === currentYear)?.adjustedValue ?? 0))}</td>
                        <td>{Format.Currency(certificatedGrowth)}</td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
        </>
    );

    return (
        <>
            <LoadingOverlay spinner text="Loading money..." active={loading}>
                <Row style={{ height: "0px" }}>
                    <Col
                        xs={11}
                        style={{
                            textAlign: "right",
                            position: "relative",
                            bottom: "-20px",
                        }}
                    >
                        <button onClick={exportPDFWithComponent} className="btn dark-red-button pdfHidden">
                            Export To PDF
                        </button>
                    </Col>
                </Row>
                <PDFExport
                    ref={pdfExportComponent}
                    paperSize="auto"
                    margin={40}
                    fileName={`Common Stock for ${user.fullName} | ${new Date().getDay()}`}
                    author="Scheels"
                >
                    <Row>
                        <div className="banner-background-cs"></div>
                    </Row>
                    <Col lg={11} xs={11}>
                        <Row>
                            <Col
                                lg={3}
                                xs={12}
                                style={{
                                    textAlign: "left",
                                    paddingLeft: "2em",
                                }}
                            >
                                <br />
                                <h3 className="padding-top-bottom" style={{ display: "none" }}>
                                    Total Gain:
                                </h3>
                                <h4 className="red-text" style={{ display: "none" }}>
                                    {Format.Currency(CalculateTotalGrowth())}
                                </h4>

                                <h2 className="padding-top-bottom" style={{ fontWeight: "bolder" }}>
                                    Current Common Stock Balance:
                                </h2>
                                <h3 className="red-text padding-top-bottom">{Format.Currency(CalculateCurrentTotal(certificates, subaccounts))}</h3>

                                <h5 className="padding-top-bottom">Shares:</h5>
                                <h5 className="red-text padding-top-bottom">{uncertShares + certShares}</h5>

                                <h4 className="padding-top-bottom">Value Per Share:</h4>
                                <h4 className="red-text padding-top-bottom">
                                    {Format.Currency(valuations.find((x) => x.year === currentYear)?.adjustedValue ?? 0, 3)}
                                    **
                                </h4>
                                <p className="padding-top-bottom">
                                    **Current value as of{" "}
                                    {valuations.filter((x) => x.year === currentYear).length > 0
                                        ? "01/01/" + String(currentYear)
                                        : "12/31/" + String(currentYear - 1)}
                                </p>
                            </Col>
                            <Col lg={4} xs={12}>
                                <ChartContainer />
                            </Col>
                            <Col lg={5} xs={12}>
                                <HistoricalChart />
                            </Col>
                        </Row>
                        <br />
                        <h1>Uncertificated Shares</h1>
                        <Table bordered hover size="sm" striped>
                            <thead className="table-header">
                                <tr>
                                    <th>Year Issued</th>
                                    <th>Shares</th>
                                    <th>Current Value</th>
                                    <th>Growth</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subaccounts.map((sub: Subaccount) => (
                                    <tr key={sub.subaccountId}>
                                        <td>{sub.year}</td>
                                        <td>{sub.shares}</td>
                                        <td>
                                            {Format.Currency(sub.shares * (valuations.find((x) => x.year === currentYear)?.adjustedValue ?? 0))}{" "}
                                            {sub.year > currentYear - 5 ? "*" : ""}
                                        </td>
                                        <td>{CalculateUncertificatedGrowth(sub)}</td>
                                    </tr>
                                ))}
                                <tr key={"total"} className="totals-Row">
                                    <td>Total</td>
                                    <td>{uncertShares}</td>
                                    <td>{Format.Currency(uncertShares * (valuations.find((x) => x.year === currentYear)?.adjustedValue ?? 0))}</td>
                                    <td>{Format.Currency(uncertificatedGrowth)}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <br />
                        {certificates.length > 0 ? CertificatedTable() : ""}

                        <p>
                            *Please note that in order for the full value to be received for a stock redemption, the stock must be held on the books for 5
                            years.
                        </p>
                        <p>
                            <strong>Content: </strong>
                            The data on this page is updated once every 24 hours and therefore transactions may not appear in real time.
                        </p>
                    </Col>
                </PDFExport>
            </LoadingOverlay>
        </>
    );
}

export default Stock;
