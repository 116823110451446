import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { IS_LOADING } from "../constants/loadingStatuses.constants";
import {
    FETCH_SUBACCOUNT,
    SET_SUBACCOUNT,
} from "./../constants/subaccount.constants";
import Subaccount from "./../../models/Subaccount";
import { ResponseStatus } from "../../models/ResponseStatus";
import * as Sentry from "@sentry/browser";
import { ActionObject } from "../../models/redux/_ActionObject";
import User from "../../models/User";
import Shareholder from "../../models/Shareholder";
type fetchSubaccountType = {
	user: User;
	shareholder: Shareholder;
};
function* fetchSubaccount(action: ActionObject<fetchSubaccountType>) {
	try {
		yield put({ type: IS_LOADING, payload: true });
		const response: ResponseStatus<Subaccount> = yield axios.get(
			`${
				process.env.REACT_APP_SEO_API ?? "api"
			}/Subaccount/GetByShareholder/${
				action.payload.shareholder.shareholderId
			}`,
			{
				headers: {
					Authorization: `bearer ${action.payload.user.tokenId}`,
				},
			}
		);
		yield put({
			type: SET_SUBACCOUNT,
			payload: response.data,
		});
	} catch (error) {
		console.log(`There was an error! -> ${error}`);
		Sentry.captureException(error);
	} finally {
		yield put({ type: IS_LOADING, payload: false });
	}
}

function* SubaccountSaga() {
    yield takeEvery(FETCH_SUBACCOUNT, fetchSubaccount);
}

export default SubaccountSaga;
