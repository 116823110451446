import * as React from "react";

function StockHistory() {
    return (
        <>
            <div>
                <img style={{ borderRadius: "0%", width: "80%" }} src={require("./pictures/2024CommonStockHistory.png")} alt="Common Stock History"></img>
            </div>
        </>
    );
}

export default StockHistory;
