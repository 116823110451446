import * as React from "react";
import { Col, Row } from "reactstrap";
import "./../custom.css";

function StockRedemption() {
    return (
        <>
            <Row>
                <div className="esop-banner">
                    <div className="clipped">
                        <h3>Common Stock Redemption</h3>
                    </div>
                </div>
            </Row>
            <br />
            <div className="d-flex flex-column justify-content-left text-left m-2" style={{ width: "90%" }}>
                <Row>
                    <p>
                        Shareholders are allowed to redeem shares owned at any time throughout the year. If stock is redeemed in the 1st quarter of the year,
                        you will receive the prior year share value. If those shares have been held for the required 5 year period, you will receive an
                        additional check for the stock growth once the new share value is approved at the March EC meeting.
                    </p>
                </Row>
                <Row>
                    <p>
                        Please complete the{" "}
                        <a href="https://forms.gle/waHDhyJsSWDzBq9WA" target="_blank">
                            Common Stock Redemption request form
                        </a>{" "}
                        or email <a href="mailto: stock@scheels.com">stock@scheels.com</a> for specific questions. Once the form has been completed, a check
                        will be issued as soon as possible. Please allow up to two weeks to receive the payment.
                    </p>
                </Row>
                <Row>
                    <p>
                        Scheels is not required to withhold taxes, or issue a 1099 form, when paying out Scheels stock. The purchase history (cost basis) will
                        be provided at the time of redemption for you to report on your annual tax filing.
                    </p>
                </Row>
            </div>
        </>
    );
}

export default StockRedemption;
