import * as React from "react";
import { Col, Row } from "reactstrap";
import "./../custom.css";

function Faq() {
  return (
    <>
      <Row>
        <div className="esop-banner">
          <div className="clipped">
            <h3>Common Stock FAQ</h3>
          </div>
        </div>
      </Row>
      <Col xs={12}>
        <div>
          <div className="page-heading"></div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/MoneyBag.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">
              When am I eligible to purchase stock?
            </h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              Associates employed full-time for 5 years and meet eligibility
              requirements as stated in Procedure #9, <br />
              Scheels Stock Purchase Eligiblity, will be invited to purchase
              stock.
            </p>
            <br />
          </div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/25 chart.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">How much am I allowed to purchase?</h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              Associates are eligible to purchase up to 25% of their prior year
              gross earnings.
            </p>
            <br />
          </div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/Callendar.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">
              Can I purchase stock at any time throughout the year?
            </h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              No. Scheels conducts one annual stock purchase each year on 7/1.
            </p>
            <br />
          </div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/MoneyCircle.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">At what value is stock redeemed?</h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              Shares are redeemed at the current year value unless purchased
              less than five years prior. <br />
              If not held for 5 years, shares are redeemed at the prior year
              value.
            </p>
            <br />
          </div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/PushMoney.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">
              Can I redeem my stock at any time throughout the year?
            </h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              Absolutely! If stock is redeemed in the 1st quarter, you will
              receive the prior year share value. <br />
              If those shares have been held for the required 5 year period,
              <br />
              you will receive an additional check for the stock growth once the
              new share value is approved at the March EC meeting. <br />
              Please follow the link{" "}
              <a
                href="https://forms.gle/ffgCh1BGnCoVS3BC6"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{" "}
              to submit a request for redemption or email{" "}
              <a href="mailto: stock@scheels.com">stock@scheels.com</a> for
              specific questions.
            </p>
            <br />
          </div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/Calculator.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">
              Will taxes be withheld from my stock redemption?
            </h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              Scheels does not withhold taxes (or issue 1099 forms) when paying
              out Scheels stock. <br />
              The purchase history (cost basis) will be provided at the time of
              redemption for you to report on your annual tax filing.
            </p>
            <br />
          </div>
          <div style={{ width: "100%" }}>
            <img
              style={{ borderRadius: "0%" }}
              src={require("./pictures/QuestionMark.PNG")}
              alt="MoneyBag"
            ></img>
            <h2 className="faq-question">
              What happens to my stock if I leave employment?
            </h2>
            <br />
          </div>
          <div className="faq-answer">
            <p>
              Associates who are no longer employed with Scheels or change
              status to part-time must redeem stock immediately. <br />
              Career associates who have been employed full-time with Scheels for 30 years or more are allowed to continue using
              the associate discount for up to 5 years following retirement. Associates terminated for misconduct do not receive this
              extended benefit.
            </p>
            <br />
          </div>
          <div
            style={{
              width: "100%",
              backgroundColor: "red",
              paddingTop: "2em",
              paddingBottom: "2em",
              color: "white",
            }}
          >
            <h4 className="faq-question">
              If you have additional questions about Scheels Common Stock,
              please reach out to stock@scheels.com.
            </h4>
          </div>
          <br />
          <br />
        </div>
      </Col>
    </>
  );
}

export default Faq;
