import { combineReducers } from 'redux';
import userReducer from './user.reducer';
import loginReducer from './login.reducer';
import loadingReducer from './loading.reducer';
import certificateReducer from './certificate.reducer';
import shareholderReducer from './shareholder.reducer';
import subaccountReducer from './subaccount.reducer';
import valuationReducer from './valuation.reducer';
import esopReducer from './esop.reducer';
import ESOPValuationReducer from './esopValuation.reducer';

// rootReducer is the primary reducer for our entire project
// It bundles up all of the other reducers so our project can use them.
// This is imported in index.js as rootSaga

// Lets make a bigger object for our store, with the objects from our reducers.
// This is what we get when we use 'state' inside of 'mapStateToProps'
const rootReducer = combineReducers({
    certificateReducer,
    shareholderReducer,
    subaccountReducer,
    valuationReducer,
    userReducer,
    loginReducer,
    loadingReducer,
    esopReducer,
    ESOPValuationReducer
});

export default rootReducer;
