import { ActionObject } from "../../models/redux/_ActionObject";
import Subaccount from "../../models/Subaccount";
import { SET_SUBACCOUNT } from "./../constants/subaccount.constants";

const defaultState: Subaccount[] = [];

function subaccountReducer(
	state = defaultState,
	action: ActionObject<Subaccount[]>
) {
	switch (action.type) {
		case SET_SUBACCOUNT:
			const newState = action.payload;
			return newState;
		default:
			return state;
	}
}

export default subaccountReducer;
