import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { IS_LOADING } from "../constants/loadingStatuses.constants";
import {
	FETCH_VALUATION,
	SET_VALUATION,
} from "./../constants/valuation.constants";
import Valuation from "./../../models/Valuation";
import { ResponseStatus } from "../../models/ResponseStatus";
import * as Sentry from "@sentry/browser";
import { ActionObject } from "../../models/redux/_ActionObject";
import { User } from "@sentry/browser";
type fetchValuationType = {
	user: User;
};
function* fetchValuation(action: ActionObject<fetchValuationType>) {
	try {
		console.log(action);
		yield put({ type: IS_LOADING, payload: true });
		const response: ResponseStatus<Valuation> = yield axios.get(
			`${process.env.REACT_APP_SEO_API ?? "api"}/Valuation/GetAll/1`,
			{
				headers: {
					Authorization: `bearer ${action.payload.user.tokenId}`,
				},
			}
		);

		yield put({
			type: SET_VALUATION,
			payload: response.data,
		});
	} catch (error) {
		console.log(`There was an error! -> ${error}`);
		Sentry.captureException(error);
	} finally {
		yield put({ type: IS_LOADING, payload: false });
	}
}


function* ValuationSaga() {
	yield takeEvery(FETCH_VALUATION, fetchValuation);
}

export default ValuationSaga;
