import { User } from "@sentry/browser";
import { ActionObject } from "../../models/redux/_ActionObject";
import { SET_USER } from "../constants/user.constants";

const userReducer = (state = {}, action: ActionObject<User>) => {
	switch (action.type) {
		case SET_USER:
			return action.payload;
		default:
			return state;
	}
};

export default userReducer;
