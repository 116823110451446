import EsopData from "../../models/esopData";
import { ActionObject } from "../../models/redux/_ActionObject";
import { SET_ESOP } from "../constants/esop.constants";

const defaultState: EsopData[] = [];

function esopReducer (
    state = defaultState,
    action: ActionObject<EsopData[]>
){
    switch (action.type){
        case SET_ESOP:
            const newState = action.payload;
            return newState;
        default:
            return state;
    }
}

export default esopReducer;