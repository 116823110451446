import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { IS_LOADING } from "../constants/loadingStatuses.constants";
import { FETCH_CERTS, SET_CERTS } from "./../constants/certificate.constants";
import Certificate from "./../../models/Certificate";
import { ResponseStatus } from "../../models/ResponseStatus";
import * as Sentry from "@sentry/browser";
import { User } from "@sentry/browser";
import { ActionObject } from "../../models/redux/_ActionObject";
import Shareholder from "../../models/Shareholder";
export const getUser = (state: User) => state.user;

type fetchCertsType = {
    user: User;
    shareholder: Shareholder;
};
function* fetchCerts(action: ActionObject<fetchCertsType>) {
    try {
        yield put({ type: IS_LOADING, payload: true });
        const response: ResponseStatus<Certificate> = yield axios.get(
            `${process.env.REACT_APP_SEO_API ?? "api"}/Certificate/GetByShareholder/${action.payload.shareholder.shareholderId}`,
            {
                headers: {
                    Authorization: `bearer ${action.payload.user.tokenId}`,
                },
            }
        );
        yield put({
            type: SET_CERTS,
            payload: response.data,
        });
    } catch (error) {
        console.log(`There was an error! -> ${error}`);
        Sentry.captureException(error);
    } finally {
        yield put({ type: IS_LOADING, payload: false });
    }
}

function* certificateSaga() {
    yield takeEvery(FETCH_CERTS, fetchCerts);
}

export default certificateSaga;
