import { ActionObject } from "../../models/redux/_ActionObject";
import Valuation from "../../models/Valuation";
import { SET_VALUATION } from "./../constants/valuation.constants";

const defaultState: Valuation[] = [];

function valuationReducer(
	state = defaultState,
	action: ActionObject<Valuation[]>
) {
	switch (action.type) {
		case SET_VALUATION:
			const newState = action.payload;
			for (let index = 0; index < newState.length; index++) {
				newState[index].adjustedValue =
					newState[index].price / newState[index].divisor;
			}
			return newState;
		default:
			return state;
	}
}

export default valuationReducer;
