import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import User from "../models/User";
import "./../custom.css";
import { Col, Row } from "reactstrap";
import "font-awesome/css/font-awesome.min.css";
import ReduxState from "../models/redux/_ReduxState";

function Home() {
  const navigate = useNavigate();
  const user: User = useSelector((store: ReduxState) => store.userReducer);
  useEffect(() => {
    if (user!.accessToken === undefined || user!.accessToken === "") {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);

  return (
    <>
      <Row>
        <Col md={12} lg={6}>
          <img
            style={{ borderRadius: "0%", width: "50%" }}
            src={require("./pictures/MyCompanyMyFuture.PNG")}
            alt="My Company My Future"
          ></img>
        </Col>
        <Col md={12} lg={6}>
          <Row>
            <div className="home-heading ">
              <p className="grey-border">
                WHAT DOES BEING AN EMPLOYEE-OWNER MEAN?
              </p>
            </div>
          </Row>
          <Row>
            <br />
            <Col xs={10}>
              <p className="home-text">
                Being an employee-owner means employees have a stake in the
                company and an opportunity to share in the rewards of the
                company's success. It also means that as an owner, employees
                have an obligation to use their talent and company resources to
                ensure the company achieves its goals. Success of the company
                will lead to personal financial rewards.
              </p>
            </Col>
            <br />
          </Row>
        </Col>
      </Row>
      <Row style={{ backgroundColor: "#dedede" }}>
        <Col xs={12}>
          {/* <Row>
						<div
							className="home-big-heading center"
							style={{ textAlign: "center", paddingTop: "1em" }}>
							<p className="white-border">Employee-Ownership</p>
						</div>
					</Row> */}
          <Row style={{ paddingTop: "1em" }}>
            <Col md={12} lg={6}>
              <Row>
                <div className="home-heading">
                  <p
                    className="white-border"
                    style={{ paddingBottom: ".7em", paddingTop: ".7em" }}
                  >
                    PRIDE OF OWNERSHIP
                  </p>
                </div>
              </Row>
              <Row>
                <p className="home-text">
                  Employee-ownership is a significant part of our culture and
                  our success stems from the dedication of our employee-owners.
                  Employee-owners have the commitment, resourcefulness, and
                  flexibility to outperform our customers' expectations, and
                  play an important role in the success of the company. We gain
                  satisfaction in knowing that our efforts have the opportunity
                  to bring us personal financial benefits greater than our
                  regular wages.
                </p>
              </Row>
            </Col>
            <Col md={12} lg={6}>
              <Row>
                <div className="home-heading ">
                  <p className="white-border">
                    HOW CAN EMPLOYEES AFFECT THE VALUE OF THE COMPANY STOCK?
                  </p>
                </div>
              </Row>
              <Row>
                <p className="home-text">
                  As employee-owners, we all have a vested interest in making
                  sure Scheels continues to grow and remains a successful
                  company. Think about yourself, your performance and your
                  career at Scheels. Do you continually strive to improve your
                  performance? Do you make Scheels a better place to work
                  because of your attitude and work ethic? We can continue to
                  perform well and our ESOP and Common Stock plan accounts will
                  grow if we are each doing our part for the betterment of our
                  company and ourselves. As long as we work together toward
                  these goals we will see continued growth in our ESOP and
                  Common Stock.
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Home;
