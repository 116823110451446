import { ActionObject } from "../../models/redux/_ActionObject";
import Shareholder from "../../models/Shareholder";
import { SET_SHAREHOLDERS } from "./../constants/shareholder.constants";

const defaultState: Shareholder[] = [];

function shareholderReducer(
	state = defaultState,
	action: ActionObject<Shareholder[]>
) {
	switch (action.type) {
		case SET_SHAREHOLDERS:
			const newState = action.payload;
			return newState;
		default:
			return state;
	}
}

export default shareholderReducer;
