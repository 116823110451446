/* eslint react/style-prop-object: 0 */
// The reason for this disable is ChartSeriesItem wants style="smooth" rather style={{ stuff }}
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import User from "../models/User";
import { FETCH_SHAREHOLDERS } from "../redux/constants/shareholder.constants";
import { IS_LOADING } from "../redux/constants/loadingStatuses.constants";
import LoadingOverlay from "react-loading-overlay";

import ReduxState from "../models/redux/_ReduxState";
import EsopData from "../models/esopData";
import { FETCH_ESOP } from "../redux/constants/esop.constants";

import "./../custom.css";

import "font-awesome/css/font-awesome.min.css";

export interface ESOPTyping {
  esoptypingId: React.Key | undefined;
  yrs: number;
  salary: any;
  esopcontributions: any;
  dowTotal: any;
  dowPercent: number;
  esopSmallTotal: any;
  esopMediumTotal: any;
  esopLargeTotal: any;
}

function EsopCalc() {
  // Create our number formatter... Taking in numbers and making them into dollars.
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const nf = new Intl.NumberFormat();

  const user: User = useSelector((store: ReduxState) => store.userReducer);
  const loading: boolean = useSelector(
    (store: ReduxState) => store.loadingReducer
  );
  const Esops: EsopData[] = useSelector(
    (store: ReduxState) => store.esopReducer
  );

  const dispatch = useDispatch();

  // This useEffect handles the calling all data from the backend to the frontend.
  useEffect(() => {
    dispatch({ type: IS_LOADING, payload: true });
    dispatch({ type: FETCH_SHAREHOLDERS, payload: { user } });

    dispatch({ type: FETCH_ESOP, payload: { user } });
  }, [dispatch, user]);

  useEffect(() => {
    setBalance(
      Esops.length > 0
        ? Esops.sort((a: EsopData, b: EsopData) => (a.year > b.year ? 1 : -1))[
            Esops.length - 1
          ]?.balance
        : 0
    );
  }, [Esops]);

  // Declaring states.
  const [income, setIncome] = React.useState<number>(30000);
  const [esopbalance, setBalance] = React.useState<number>(0);

  const year = 20;
  //const [year, setYear] = React.useState<number>(20);

  const DowClass = function (value: number) {
    if (value < 0) {
      return "red-text";
    } else {
      return "";
    }
  };

  // main component that does the math for the values.
  const EsopCalculations = function () {
    let finalData: ESOPTyping[] = []; //@todo type me!

    let yearlyIncome = Number(income);
    let salaryGrowth = 0;
    let contributionEsop = 0;
    let selectedYear = 1;
    let shownYear = new Date().getFullYear();

    //totals
    let totalDowYearly = 0;
    let totalEsopSmallYearly = 0;
    let totalEsopMediumYearly = 0;
    let totalEsopLargeYearly = 0;

    let previousDow = 0;
    let previousEsopSmall = 0;
    let previousEsopMedium = 0;
    let previousEsopLarge = 0;

    //percents dow and small esop will come from arrays
    //negative years will be less then 1
    let percentDow = 0;
    let percentDowColumn = 0;
    let percentsDow = [
      0.8324, 1.2532, 1.0315, 0.9939, 1.1629, 1.0643, 0.6616, 1.1882, 1.1102,
      1.0553, 1.0726, 1.265, 1.0752, 0.9777, 1.1342, 1.2508, 0.9437, 1.2234,
      1.0725, 1.1873,
    ];

    //let percentEsopColumn = 0;
    //let percentsSmallEsop = [
    //1.0047, 1.3453, 1.0674, 1.2648, 1.1513, 1.1666, 1.1452, 1.4547, 1.4363,
    //1.5021, 1.6052, 1.2693, 1.0921, 1.0717, 1.1618, 1.0965, 1.3263, 1.2634,
    //1.6202, 1.7574,
    //];
    let percentSmallEsop = 1.1;
    let percentMediumEsop = 1.15;
    let percentLargeEsop = 1.25;

    for (selectedYear; selectedYear <= year; selectedYear++) {
      if (selectedYear === 1) {
        // year 1 we do not get esop
        // Showing the contributions and salary and how they are incrementing after the first year.
        salaryGrowth = yearlyIncome;

        //get the percent for that year from the arrays
        //convert the percent to
        percentDow = percentsDow[selectedYear - 1];

        //esop contribution amount of 4%
        contributionEsop = Number(
          (Math.round(salaryGrowth * 0.04 * 100) / 100).toFixed(2)
        );

        // dow calcs
        totalDowYearly = Number(
          Math.round(
            (contributionEsop + previousDow + esopbalance) * percentDow
          ).toFixed(2)
        );
        previousDow = totalDowYearly;

        //esop calcs
        totalEsopSmallYearly = Number(
          Math.round(
            (contributionEsop + previousEsopSmall + esopbalance) *
              percentSmallEsop
          ).toFixed(2)
        );
        previousEsopSmall = totalEsopSmallYearly;

        totalEsopMediumYearly = Number(
          Math.round(
            (contributionEsop + previousEsopMedium + esopbalance) *
              percentMediumEsop
          ).toFixed(2)
        );
        previousEsopMedium = totalEsopMediumYearly;

        totalEsopLargeYearly = Number(
          Math.round(
            (contributionEsop + previousEsopLarge + esopbalance) *
              percentLargeEsop
          ).toFixed(2)
        );
        previousEsopLarge = totalEsopLargeYearly;
      } else {
        // Showing the contributions and salary and how they are incrementing after the first year.
        salaryGrowth = Number(
          (Math.round(yearlyIncome * 1.03 * 100) / 100).toFixed(2)
        );
        contributionEsop = Number(
          (Math.round(salaryGrowth * 0.04 * 100) / 100).toFixed(2)
        );
        //get the percent for that year from the arrays
        //convert the percent to
        percentDow = percentsDow[selectedYear - 1];

        //dow calcs
        totalDowYearly = Number(
          Math.round((contributionEsop + previousDow) * percentDow).toFixed(2)
        );
        previousDow = totalDowYearly;

        //esop calcs
        totalEsopSmallYearly = Number(
          Math.round(
            (contributionEsop + previousEsopSmall) * percentSmallEsop
          ).toFixed(2)
        );
        previousEsopSmall = totalEsopSmallYearly;

        totalEsopMediumYearly = Number(
          Math.round(
            (contributionEsop + previousEsopMedium) * percentMediumEsop
          ).toFixed(2)
        );
        previousEsopMedium = totalEsopMediumYearly;

        totalEsopLargeYearly = Number(
          Math.round(
            (contributionEsop + previousEsopLarge) * percentLargeEsop
          ).toFixed(2)
        );
        previousEsopLarge = totalEsopLargeYearly;

        // These calls are the one helping increment those amount for each year after.
        if (salaryGrowth < 290000) {
          yearlyIncome = salaryGrowth;
        }

        // calculating the growth of the esop and it's total by the given percentages
      }

      percentDowColumn = (percentDow - 1) * 100;
      //percentEsopColumn = (percentSmallEsop - 1) * 100;
      // the console.Log just to show me what I have going on.
      // console.log("selected year", selectedYear, "year", year, "amount", previousAmount, "salary", salaryGrowth, "contributions", contributionEsop, "Total of Esop", totalEsopAmountYearly);
      // Passing it in the ESOPTyping (Type that I created) // Then passing it into object to be accessed in an array.
      let dataobjet = {
        esoptypingId: year,
        yrs: shownYear + (selectedYear - 1),
        salary: salaryGrowth,
        esopcontributions: contributionEsop,
        dowTotal: totalDowYearly,
        dowPercent: percentDowColumn,
        esopSmallTotal: totalEsopSmallYearly,
        esopMediumTotal: totalEsopMediumYearly,
        esopLargeTotal: totalEsopLargeYearly,
      };

      // putting all in a array to be mapped over in the function below.
      finalData.push(dataobjet);
    }

    return finalData;
  };

  // The default table with 30.000 plus as salary.
  const CertificatedTable = () => (
    <>
      <div className="col-12">
        <Table bordered hover size="sm" striped>
          <thead className="table-header">
            <tr>
              <th>Year</th>
              <th>
                <span id="ttescon">
                  Estimated
                  <br /> Contribution
                  <i className="fas fa-solid fa-info-circle pl-1"></i>
                </span>
                <UncontrolledTooltip placement="right" target="ttescon">
                  The estimated contribution is calculated at 4% of your gross
                  salary with an estimated 3% salary increase each year.
                </UncontrolledTooltip>
              </th>
              <th>
                <span id="tthdjp">
                  Historical
                  <br /> Dow Jones %
                  <i className="fas fa-solid fa-info-circle pl-1"></i>
                </span>
                <UncontrolledTooltip placement="right" target="tthdjp">
                  The percentages referenced in this column reflect actual
                  performance of the Dow Jones over the last 20 years
                  (2002-2021). For example, the 2002 performance of the Dow was
                  -16.76% which is being shown as the 2022 performance. While
                  this illustration doesn't predict the actual performance in
                  2022 and into the future, these historical values show the
                  volatility when investing in the public stock market.
                </UncontrolledTooltip>
              </th>
              <th>
                <span id="ttdjb">
                  Estimated
                  <br /> Dow Jones balance
                  <i className="fas fa-solid fa-info-circle pl-1"></i>
                </span>
                <UncontrolledTooltip placement="right" target="ttdjb">
                  The estimated balance based on the annual contribution and
                  historical performance of the Dow Jones.
                </UncontrolledTooltip>
              </th>
              <th>
                <span id="tthsep">
                  Scheels ESOP based on 10%
                  <i className="fas fa-solid fa-info-circle pl-1"></i>
                </span>
                <UncontrolledTooltip placement="right" target="tthsep">
                  The estimated balance based on the annual contribution and the
                  average ESOP growth of 10% per year.
                </UncontrolledTooltip>
              </th>
              <th>
                <span id="tthseb">
                  Scheels ESOP based on 15%
                  <i className="fas fa-solid fa-info-circle pl-1"></i>
                </span>
                <UncontrolledTooltip placement="right" target="tthseb">
                  The estimated balance based on the annual contribution and the
                  average ESOP growth of 15% per year.
                </UncontrolledTooltip>
              </th>
              <th>
                <span id="tteseb">
                  Scheels ESOP based on 25%
                  <i className="fas fa-solid fa-info-circle pl-1"></i>
                </span>
                <UncontrolledTooltip placement="left" target="tteseb">
                  The estimated balance based on the annual contribution and the
                  historical average ESOP growth of 25% per year.
                </UncontrolledTooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            {EsopCalculations().map((result: ESOPTyping) => (
              <tr>
                <td>{result.yrs}</td>
                <td className="table-boarder-right">
                  {formatter.format(result.esopcontributions)}
                </td>
                <td className={DowClass(result.dowPercent)}>
                  {Number(result.dowPercent).toFixed(2)}%
                </td>
                <td>{formatter.format(result.dowTotal)}</td>
                <td className="table-boarder-left">
                  {formatter.format(result.esopSmallTotal)}
                </td>
                <td>{formatter.format(result.esopMediumTotal)}</td>
                <td className="table-boarder-right">
                  {formatter.format(result.esopLargeTotal)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );

  // this is What's rendered if the user inputes anything under 10.000 an empty table.
  const defaultTable = () => (
    <>
      <Table bordered hover size="sm" striped>
        <thead className="table-header">
          <tr>
            <th>Years</th>
            <th>Salary</th>
            <th>ESOP Contribution</th>
            <th>Total Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0</td>
            <td>{formatter.format(0)}</td>
            <td>{formatter.format(0)}</td>
            <td>{formatter.format(0)}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );

  return (
    <>
      <Row>
        <div className="esop-banner">
          <div className="clipped">
            <h3>ESOP Projection Tool</h3>
          </div>
        </div>
      </Row>
      <LoadingOverlay spinner text="Loading money..." active={loading}>
        <Row
          md={6}
          lg={12}
          xl={6}
          style={{ paddingTop: "1em", paddingBottom: "1em" }}
        >
          <Col md={4} lg={4}>
            <label>Yearly Income:</label>

            <InputGroup>
              <InputGroupText>$</InputGroupText>
              <Input
                placeholder="Yearly Income"
                type="text"
                value={nf.format(income)}
                onChange={(e) =>
                  setIncome(
                    Number(e.target.value.replace(/,/g, "")) > 290000
                      ? 290000
                      : Number(e.target.value.replace(/,/g, ""))
                  )
                }
              />
            </InputGroup>
          </Col>
          <Col md={4} lg={4}>
            <label>Balance:</label>

            <InputGroup>
              <InputGroupText>$</InputGroupText>
              <Input
                placeholder="Starting Balance"
                type="text"
                value={nf.format(Number(esopbalance))}
                disabled={false}
                onChange={(e) =>
                  setBalance(Number(e.target.value.replace(/,/g, "")))
                }
              />
            </InputGroup>
          </Col>
          <Col
            md={7}
            lg={7}
            xl={7}
            style={{ paddingTop: "1em", textAlign: "left" }}
          >
            <div>
              <p>
                This projection tool is an estimate of your annual ESOP
                contribution and potential growth from this point forward, using
                an estimated average ESOP growth of 10%, 15% and 25% each year.
                As a reminder, there are <u>no guarantees</u> for annual ESOP
                performance! Past performance does not guarantee future
                performance. Continued growth in the ESOP is up to each and
                every associate. As employee-owners, we all have a vested
                interest in making sure Scheels continues to grow and remains a
                successful company.
              </p>
              <p>
                The Dow Jones is a stock market index of 30 prominent companies
                listed on stock exchanges in the United States and is an index
                that helps measure the stock market's overall direction. It is
                included as a comparison in this projection tool to show what
                the return of your investment would be in a publicly traded
                market versus how it would perform in the private Scheels ESOP
                Plan. This scenario would be if you were contributing to a
                personal investment account. Unlike the ESOP, where Scheels
                contributes to the Plan on your behalf.
              </p>
            </div>
          </Col>
        </Row>

        <hr />
        {/* If the value inputed is 0 to 10.000 the chart would not  display anything */}
        {income.valueOf() < 10000 ? defaultTable() : <CertificatedTable />}
      </LoadingOverlay>
    </>
  );
}

export default EsopCalc;
