import { put, takeEvery } from "redux-saga/effects";
import User from "../../models/User";
import { AUTHENTICATION_FAILED, AUTHENTICATION_PROCESSING, AUTHENTICATION_SUCCEEDED } from "../constants/loadingStatuses.constants";
import { SET_USER, VALIDATE_USER } from "../constants/user.constants";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import moment from "moment";
import { ActionObject } from "../../models/redux/_ActionObject";
import jwtDecode from "jwt-decode";
export interface iGoogleValidationPayload {
    googleResponse: string;
    token: string;
}
function* validateUser(action: ActionObject<iGoogleValidationPayload>) {
    try {
        yield put({ type: AUTHENTICATION_PROCESSING });
        let user = new User(action.payload.googleResponse, action.payload.token);
        console.log(action.payload);
        console.log(jwtDecode(action.payload.googleResponse));
        const authResult: GoogleAdminResponse = yield axios.get(
            `https://admin.googleapis.com/admin/directory/v1/users/${user.email}?projection=CUSTOM&viewType=domain_public`,
            {
                headers: {
                    Authorization: `Bearer ${user.accessToken}`,
                },
            }
        );
        if (authResult.data.externalIds.length === 0) {
            throw new Error("Unable to acquire EmployeeID from Google Admin Directory API for employee " + user.email);
        }
        user.employeeId = authResult.data.externalIds[0].value;
        user.loginTime = moment();
        yield put({ type: SET_USER, payload: user });
        yield put({ type: AUTHENTICATION_SUCCEEDED });
    } catch (error) {
        yield put({ type: AUTHENTICATION_FAILED });
        Sentry.captureException(error);
    }
}

function* userSaga() {
    yield takeEvery(VALIDATE_USER, validateUser);
}

export default userSaga;

export interface GoogleAdminResponse {
    config?: any;
    data?: any;
    headers?: any;
    request?: any;
    status?: number;
    statusText?: string;
}
