import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { IS_LOADING } from "../constants/loadingStatuses.constants";
import { User } from "@sentry/browser";
import { ActionObject } from "../../models/redux/_ActionObject";
import ESOPValuation from "../../models/esopValuation";
import { ResponseStatus } from "../../models/ResponseStatus";
import * as Sentry from "@sentry/browser";
import { FETCH_ESOP_VALUATION, SET_ESOP_VALUATION } from "../constants/esopValuation.constants";
type fetchEsopValuationType = {
    user: User;
};

function* fetchEsopValuation(action: ActionObject<fetchEsopValuationType>) {
    try {

        console.log(action);
        yield put({ type: IS_LOADING, payload: true });
        const response: ResponseStatus<ESOPValuation> = yield axios.get(
            `${process.env.REACT_APP_SEO_API ?? "api"}/ESOP/GetEsopValuations`,
            {
                headers: {
                    Authorization: `bearer ${action.payload.user.tokenId}`,
                },
            }
        );

        yield put({
            type: SET_ESOP_VALUATION,
            payload: response.data,
        });
        
    } catch (error) {
        console.log(`There was an error getting ESOPValuation! -> ${error}`);
        Sentry.captureException(error);
    } finally {
        yield put({ type: IS_LOADING, payload: false });
    }

};

function* esopValuationSaga() {
    yield takeEvery(FETCH_ESOP_VALUATION, fetchEsopValuation);
}

export default esopValuationSaga;

